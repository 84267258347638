require.config({
    paths: {
        swiper: 'swiper.min',
    }
});
const $j = jQuery.noConflict();
const silk = {
    route: function (roteJson) {
        let page;
        for (page in roteJson) {
            if ($j(page).length) {
                roteJson[page]();
            }
        }
    },
    toggle: function (x, y, ev, fn) {
        let theBtn = $j(x);
        let theP = $j(y);
        theBtn.each(
            function (i) {
                $j(this).click(function (e) {
                        if (ev === true) {
                            e.stopPropagation()
                        }
                        $j(this).toggleClass("closeUp");
                        theP.eq(i).slideToggle();
                    }
                )
            }
        );
        if (fn) {
            fn(x, y)
        }
    },
    async_toggle: function (x, p, y, ev) {
        $j(x).each(function () {
            $j(this).click(function (e) {
                if (ev === true) {
                    e.stopPropagation();
                }
                if(y){
                   if(p){
                       if($j(p).hasClass(y)){
                           $j(p).removeClass(y).hide();
                       }else{
                           $j(p).addClass(y).show();
                       }
                   }else{
                       $j(this).next().addClass(y).show();
                   }
                }else{
                    $j(this).toggleClass("closeUp").next().slideToggle();
                }

            });
        });

    },
    addIn: function addIn(x, y, fn) {
        $j(x).each(function (k, v) {
            $j(this).click(function () {
                if ($j(this).hasClass(y)) {
                    return
                }
                $j(this).addClass(y).siblings().removeClass(y);

                if (fn) {
                    fn(k, v)
                }
            })
        });
    },
    addCookie: function (name, value, options) {
        if (arguments.length > 1 && name !== null) {
            if (options === null) {
                options = {};
            }
            if (value === null) {
                options.expires = -1;
            }
            if (typeof options.expires === "number") {
                let time = options.expires;
                let expires = options.expires = new Date();
                expires.setTime(expires.getTime() + time * 1000);
            }
            document.cookie = encodeURIComponent(String(name)) + "=" + encodeURIComponent(String(value)) + (options.expires ? "; expires=" + options.expires.toUTCString() : "") + (options.path ? "; path=" + options.path : "") + (options.domain ? "; domain=" + options.domain : "") + (options.secure ? "; secure" : "");
        }
    },
    getCookie: function (name) {
        if (name !== null) {
            let value = new RegExp("(?:^|; )" + encodeURIComponent(String(name)) + "=([^;]*)").exec(document.cookie);
            return value ? decodeURIComponent(value[1]) : null;
        }
    },
    removeCookie: function (name, options) {
        this.addCookie(name, null, options);
    },
    addDefaultCookie: function (name, value) {
        this.addCookie(name, value, {path: "/", expires: 30 * 24 * 3600})
    },
    changeQty: function (currDom, doAction) {
        $j(currDom).click(function () {
            let qtyDom = $j(this).siblings('input');
            let currNum = parseInt(qtyDom.val());
            if (doAction === 'plus') {
                qtyDom.val(currNum + 1);
            } else {
                if (currNum > 1) {
                    qtyDom.val(currNum - 1);
                }
            }
        });
    },
    checkRadio: function (k, v) {
        $j(k).find('input').attr('checked', 'checked');
        $j(k).siblings().find('input').attr('checked', '');
    },
    isChecked: function (x, y) {
        $j(x).each(function () {
            if ($j(this).find('input').attr('checked') == 'checked') {
                $j(this).addClass(y)
            }
        })
    },
    isMob: function () {
        return window.innerWidth <= 768
    }
};

$j(function () {
    pagePublic();
    silk.route({
        ".cms-index-index": pageHome,
        // ".customer-quick-create,.wechat-bind-view": pageCreate,
        // ".catalogsearch-advanced-index": pageSearch,
        ".catalog-category-view": pageList,
        // ".catalogsearch-result-index": pageList,
        ".catalog-product-view": pageProduct,
        ".checkout-cart-index": pageCart,
        ".checkout-onepage-index": pageCheckout,
        ".catalogsearch-result-index": searchPage,
        // ".customer-account-index": pageDashboard,
        // ".wishlist-index-index": pageWishlist,
        // ".browsehistory-index-index": pageHistory,
        // ".customer-address-index": pageAddress,
        // ".category-index-deliverytohome,.category-index-instorepickup": pageMenu,
        // ".sales-order-history": orderList,
        // ".sales-order-view": orderView,
        // ".magento_rma-returns-history": rmaList,
        // ".magento_rma-returns-view": rmaView,
        // ".checkout-onepage-success": pageCheckoutSuccess,
        // ".coupon-coupon-index": pageCoupon,
        // ".rewardcard-info-index,.rewardcard-index-index": pageReward,
        // ".shippingcoupon-coupon-info": pageShippingcoupon,
        // ".shippingcoupon-checkout-index": pageShippingServe,
        // "#tnt_rewards": pageRewards,
    });
});


function pagePublic() {
    if(silk.isMob()){
        $j("body").addClass("mob");
    }
    else {
        $j("body").removeClass("mob")
    }
    window.onresize = function () {
        if(silk.isMob()){
            $j("body").addClass("mob")
        }
        else {
            $j("body").removeClass("mob")
        }
    };
    silk.async_toggle(".mob .block-title",".links");
    $j('.NoticeContainer span.close').click(function () {
        $j('.topNotice').hide();
    });


    $j('.mob .navbar-inverse #nav > li').each(function () {

        $j(this).click(function () {
            $j(this).siblings().removeClass('closeUp');
            $j(this).siblings().find('.nav-wrapper').slideUp()
            if($j(this).hasClass('parent') && $j(this).hasClass('closeUp')){
                $j(this).removeClass('closeUp')
                $j(this).find('.nav-wrapper').slideUp();
            }else{
                $j(this).addClass('closeUp')
                $j(this).find('.nav-wrapper').slideDown()
            }
        })
    })



    require(['swiper'], function (Swiper) {
        let hotKey = new Swiper('.mob .hot_key.swiper-container', {
            loop: false,
            slidesPerView: 'auto',
            scrollbar: {
                el: '.swiper-scrollbar',
            },
        });
    });

    silk.toggle('.nav-toogle span', '.navbar');
    silk.async_toggle('.mob .mobileTopLinks span');
}

function pageHome() {
    $j('.nav-toogle span').addClass('closeUp');

    require(['swiper'], function (Swiper) {

        let mobBanner = new Swiper('.top_banner .mobileHidden>.swiper-container', {
            loop: true, // 循环模式选项
            // 如果需要分页器
            pagination: {
                el: '.top_banner .mobileHidden .swiper-pagination',
                type: 'bullets',
                clickable: true,
                clickableClass : 'my-pagination-clickable',
            }
        });

        let pcBanner = new Swiper('.top_banner .pcHidden >.swiper-container', {
            loop: true, // 循环模式选项
            // 如果需要分页器
            pagination: {
                el: '.top_banner .pcHidden .swiper-pagination',
                type: 'bullets',
                clickable: true,
                clickableClass : 'my-pagination-clickable',
            }
        });

        let productsBanner = new Swiper('.roll_products.swiper-container', {
            loop: true, // 循环模式选项
            // 如果需要分页器
            slidesPerView: 2,
            slidesPerGroup: 2,
            pagination: {
                el: '.roll_products .swiper-pagination',
                clickable: true,
            },
            spaceBetween: 10,
            breakpoints: {
                640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                    slidesPerGroup: 2,
                },
                768: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                    spaceBetween: 40,
                },
                1024: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    spaceBetween: 22,
                },
            }
        })
    })
}

function pageList() {
    $j('.main').prepend($j('.toolbar-top'));
    $j('.filter-item').first().addClass('closeUp');

    if(silk.isMob()){
        silk.async_toggle('.listActions li.filter', '.leftFilter', 'popup');
        silk.async_toggle('dd.filter-item .filter_title', '', 'popup');
        silk.async_toggle('.leftFilter .block-subtitle span', '.leftFilter', 'popup')
        silk.async_toggle('.options-subtitle span', '.filters-options ', 'popup');
        if($j('.currently').length >0){

        }
    }else{
        // silk.toggle('.filter-item .filter_title');
        $j('.filter-item').each(function () {
            let _this = $j(this);
            $j(this).find('.filter_title').click(function () {
                if(_this.hasClass('closeUp')){
                    _this.removeClass('closeUp');
                    // $j(this).next().slideUp(30000)
                }else{
                    _this.addClass('closeUp');
                    // $j(this).next().slideDown('slow')
                }

            })
        })
    }
}

function pageProduct() {
    silk.changeQty('.to-cart-container span.reduce', 'reduce');
    silk.changeQty('.to-cart-container span.plus', 'plus');

    require(['swiper'], function (Swiper) {
        //related product
        let relatedProduct = new Swiper('.block-related .swiper-container', {
            loop: false, // 循环模式选项
            watchSlidesVisibility: true,
            slidesPerView: 4,
            breakpoints: {
                320: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 2
                },
                980: {
                    slidesPerView: 3
                },
                1200: {
                    slidesPerView: 4
                },
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
                clickableClass : 'my-pagination-clickable',
            },
            spaceBetween: 20,

        });
        //recommend product
        let recommendProduct = new Swiper('.recommend_product.swiper-container', {
            loop: false, // 循环模式选项
            watchSlidesVisibility: true,
            slidesPerView: 4,
            breakpoints: {
                320: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 2
                },
                980: {
                    slidesPerView: 3
                },
                1200: {
                    slidesPerView: 4
                },
            },
            pagination: {
                el: '.recommend-swiper-pagination',
                type: 'bullets',
                clickable: true,
                clickableClass : 'recommend-pagination-clickable',
            },
            spaceBetween: 20,

        });
        //product image more view
        if(silk.isMob()){
            let mobileGallery = new Swiper('.more-views .swiper-container', {
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true,
                    clickableClass : 'my-pagination-clickable',
                },
            });
        }else{
            let thumbGallery = new Swiper('.more-views .swiper-container', {
                loop: false, // 循环模式选项
                watchSlidesVisibility: true,
                slidesPerView: 4,
                breakpoints: {

                    768: {
                        slidesPerView: 2
                    },
                    980: {
                        slidesPerView: 3
                    },
                    1200: {
                        slidesPerView: 4
                    },
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                spaceBetween: 10,

            });


        }
    });
    if(silk.isMob()){
        $j('.tabs_title').each(function () {
           if($j(this).index() == 0){
               $j(this).addClass('closeUp');
           }
        });
        silk.async_toggle('.tabs_title')
    }

}


function pageCart() {
    silk.changeQty('.to-cart-container span.reduce', 'reduce');
    silk.changeQty('.to-cart-container span.plus', 'plus');
    if ($j('.cart-empty').length) {
        $j('body').addClass('checkout-cart-empty');
    }
}

function pageCheckout() {
    silk.isChecked('.guestCheckout ul li', 'active');
    silk.addIn('.guestCheckout ul li', 'active', silk.checkRadio('.guestCheckout ul li', 'active'));


    silk.isChecked('#co-billing-form ul.form-list li.control', 'active');
    silk.addIn('#co-billing-form ul.form-list li.control', 'active');


    silk.isChecked('#checkout-step-shipping ul.form-list li.control', 'active');
    silk.addIn('#checkout-step-shipping ul.form-list li.control', 'active');


    silk.isChecked('#checkout-payment-method-load > ul.form-list li', 'active');
    silk.addIn('#checkout-payment-method-load > ul.form-list li', 'active');

}

function searchPage() {
    silk.async_toggle('.block-layered-nav dt', 'dl');
}

